.designBaseWrapper {
    padding: 32px 16px;
}

.designAccordions {
    border: 1px solid inherit;
    border-radius: 8px;
    margin-bottom: 8px;
}

.designAccordions::before {
    display: none !important;
}