.templateBaseWrapper {
    padding: 32px 16px;
}

.templateBaseCardButton {
    width: 100% !important;
    border-radius: 8px !important;
}

.selectedTemplateBaseCardButton {

}

.selectedTemplateBaseCardButton .templateBaseCardPaper {
    border: 2px solid #afc928 !important;
    border-radius: 6px !important;
}

.templateBaseCardPaper {
    width: 100% !important;
}

.templateBaseCardImage {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
}

.templateBaseCardTextWrapper {
    padding: 16px
}