
.mainWrapper {
    padding: 32px 0 0 48px;
}

.menuPaper {
    margin-top: 32px;
    width: 100%;
    min-height: 50vh;
}

.mainTabIcon {
    fill: #000;
}